/* eslint-disable jsx-a11y/anchor-is-valid */
// Libraries
import React from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
// ICONS
import IconHome from "../../assets/icons/home.svg";
import IconStats from "../../assets/icons/stats.svg";
import IconAdminUsers from "../../assets/icons/admin_users.svg";
import IconUpload from "../../assets/icons/upload.svg";
import IconAdminGoals from "../../assets/icons/admin_goals.svg";
import IconProfile from "../../assets/icons/profile.svg";
import IconSearch from "../../assets/icons/search.svg";
import IconStar from "../../assets/icons/star_outlined.svg";
import IconEditStar from "../../assets/icons/award.svg";

const Navbar = ({ logged, usuario,userInfo }) => {
  if (!logged || !usuario) return null;

  return (
    <nav className="navbar navbar-light bg-lead verticalNavBar d-none d-md-block">
      <ul className="navbar-nav h-100">
        <ReactTooltip />
        {
          // Home
          usuario.puede.entrarA.home && (
            <li className="nav-item text-center menuItem" data-tip="Home">
              <Link
                className="nav-link-item card-hover-big"
                to={`${process.env.PUBLIC_URL}/`}
              >
                <img
                  src={IconHome}
                  alt="Logo de liverpool"
                  className="nav-icon text-white"
                />
              </Link>
            </li>
          )
        }

        {
          // Estadísticas
          usuario.puede.entrarA.estadisticas && (
            <li className="nav-item text-center menuItem" data-tip="Avances">
              <ReactTooltip />
              <Link
                className="nav-link-item card-hover-big"
                to={`${process.env.PUBLIC_URL}/estadisticas`}
              >
                <img
                  src={IconStats}
                  alt="Logo de liverpool"
                  className="nav-icon text-white"
                />
              </Link>
            </li>
          )
        }

        {
          // Medallero
          usuario.puede.entrarA.medallero && (
            <li className="nav-item text-center menuItem" data-tip="Medallero">
              <ReactTooltip />
              <Link
                className="nav-link-item menuItem card-hover-big"
                to={`${process.env.PUBLIC_URL}/medallero`}
              >
                <img
                  src={IconStar}
                  alt="Estrella"
                  className="nav-icon text-white"
                />
              </Link>
            </li>
          )
        }

        {
          // Administración de medallas
          usuario.puede.entrarA.editarMedallero && (
            <li
              className="nav-item text-center menuItem"
              data-tip="Editar medallero"
            >
              <ReactTooltip />
              <Link
                className="nav-link-item menuItem card-hover-big"
                to={`${process.env.PUBLIC_URL}/editar-medallero`}
              >
                <img
                  src={IconEditStar}
                  alt="Estrella"
                  className="nav-icon text-white"
                />
              </Link>
            </li>
          )
        }

        {
          // Administración de metas
          usuario.puede.entrarA.administracionDeMetas && (
            <li
              className="nav-item text-center menuItem"
              data-tip="Administración de metas"
            >
              <ReactTooltip />
              <Link
                className="nav-link-item menuItem card-hover-big"
                to={`${process.env.PUBLIC_URL}/administracion-de-metas`}
              >
                <img
                  src={IconAdminGoals}
                  alt="Logo de liverpool"
                  className="nav-icon text-white"
                />
              </Link>
            </li>
          )
        }

        {
          // Usuarios
          usuario.puede.entrarA.usuarios && (
            <li
              className="nav-item text-center menuItem"
              data-tip="Administración de usuarios"
            >
              <ReactTooltip />
              <Link
                className="nav-link-item menuItem card-hover-big"
                to={`${process.env.PUBLIC_URL}/usuarios`}
              >
                <img
                  src={IconAdminUsers}
                  alt="Usuarios"
                  className="nav-icon text-white"
                />
              </Link>
            </li>
          )
        }

        {
          // Carga de archivos
          usuario.puede.entrarA.cargaDeArchivos && (
            <li
              className="nav-item text-center menuItem"
              data-tip="Carga de archivos"
            >
              <ReactTooltip />
              <Link
                className="nav-link-item menuItem card-hover-big"
                to={`${process.env.PUBLIC_URL}/carga-de-metas`}
              >
                <img
                  src={IconUpload}
                  alt="Blanco con dardos"
                  className="nav-icon text-white"
                />
              </Link>
            </li>
          )
        }

        {
          // Perfil
          usuario.puede.entrarA.perfil && (
            <li className="nav-item text-center menuItem" data-tip="Tu perfil">
              <ReactTooltip />
              <Link
                className="nav-link-item menuItem card-hover-big"
                to={`${process.env.PUBLIC_URL}/perfil`}
              >
                <img
                  src={IconProfile}
                  alt="Perfil"
                  className="nav-icon text-white"
                />
              </Link>
            </li>
          )
        }
        {/* {
          // Perfil
          userInfo && userInfo.cadenaComercial === "1" && (
            <li className="nav-item text-center menuItem" data-tip="Avance Movimientos">
              <ReactTooltip />
              <Link
                className="nav-link-item menuItem card-hover-big"
                to={`${process.env.PUBLIC_URL}/buscar`}
              >
                <img
                  src={IconSearch}
                  alt="Perfil"
                  className="nav-icon text-white"
                />
              </Link>
            </li>
          )
        } */}
      </ul>
    </nav>
  );
};

export default Navbar;
